/*Slab Serif */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*Serif*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,700;1,8..144,800;1,8..144,900&display=swap');
/*Sans Serif*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

@import 'tippy.js/dist/tippy.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  There are more weights in the /fonts folder. Feel free to re-map the different weights to varations
*/

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: italic;
  src: url("/assets/fonts/Poppins-LightItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: italic;
  src: url("/assets/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
  src: url("/assets/fonts/Poppins-MediumItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
  src: url("/assets/fonts/Poppins-BoldItalic.ttf");
}


/* .voicePlayer audio::-webkit-media-controls-current-time-display,audio::-webkit-media-controls-time-remaining-display
{
    display:none;
}
.voicePlayer audio::-webkit-media-controls-mute-button { display: none !important; } audio::-webkit-media-controls-volume-slider { display: none !important; }
.voicePlayer > div{
  width: 100%;
}
.voicePlayer audio{
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  object-fit: contain;
}
audio{
  width: 100%;
} */

/* audio progress */

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #ddd;
  border-radius: 2px;
}
.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: #868686;
  border-radius: 50px;
  box-shadow: rgba(134,134,134,.5) 0 0 5px;
}
.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #868686;
  border-radius: 2px;
}

.cstmaudioVoiceWrapper .rhap_controls-section {
  background: #fff;
  border-radius: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.cstmaudioVoiceWrapper .rhap_main-controls {
  line-height: 1;
}
.cstmaudioVoiceWrapper .rhap_main-controls .rhap_main-controls-button.rhap_play-pause-button {
  color: #fff;
  background: #00C0C0;
  border-radius: 50%;
  height: 29px;
  width: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cstmaudioVoiceWrapper .rhap_controls-section .rhap_progress-container .rhap_progress-bar {
  height: 3px;
  background-color: #D9D9D9;
}
.cstmaudioVoiceWrapper .rhap_controls-section .rhap_time {
  font-size: 15px;
  color: #525252;
}
.cstmaudioVoiceWrapper .rhap_controls-section .rhap_volume-controls {
  line-height: 1;
  color: #a2a7a7;
  margin: 0 6px;
}
.cstmaudioVoiceWrapper .rhap_controls-section .rhap_progress-container .rhap_progress-bar .rhap_progress-indicator {
  box-shadow: none;
  opacity: 0;
}
.cstmaudioVoiceWrapper .rhap_controls-section .rhap_progress-container .rhap_progress-bar .rhap_progress-filled {
  background-color: #00C0C0;
}

.voiceThumbsHolder .voicePlayer .rhap_controls-section {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background: #3D3C3C;
  border-radius: 60px;
  padding: 3px 4px;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_main-controls {
  line-height: 1;
  display: inline-flex;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_main-controls .rhap_main-controls-button.rhap_play-pause-button {
  color: #00C0C0;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_main-controls .rhap_main-controls-button.rhap_play-pause-button svg {
  height: 16px;
  width: 16px;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_progress-container .rhap_progress-bar {
  height: 1px;
  background-color: #fff;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_progress-container .rhap_progress-bar .rhap_progress-indicator {
  display: none;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_progress-container .rhap_progress-bar .rhap_progress-filled {
  background-color: #00c0c0;
}
.voiceThumbsHolder .voicePlayer .rhap_controls-section .rhap_progress-container {
  margin-left: 3px;
  margin-right: 6px;
}

.horizonLine {
  padding-right: 10px;
  padding-left: 10px;
}

.cstmScrub_bar-outer__holder {
  background-color: #d9d9d9;
  height: 3px;
  margin: 0;
  max-width: 100%;
  width: 100%;
  left: 0;
  right: 0;
}

.cstmScrub_bar-outer__holder .p-4-0, .cstmScrub_bar-outer__holder .p-4-2 {
  background: #00C0C0;
  height: 3px;
  width: auto;
  padding: 0;
}

.cstmScrub_bar-outer {
  position: relative;
}

.cstmScrub_bar {
  position: absolute;
  height: 50px;
  width: 2px;
  background: #FF0069;
  left: 0;
  right: auto;
  top: -15px;
}

.cstmScrub_bar:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #FF0069;
  bottom: -18px;
  left: -9px;
}

.slider-point-value {
  margin-top: 25px;
  min-height: 20px;
  display: inline-block;
  color: #fff;
  z-index: 9999;
  position: absolute;
  left: -14px;
  border-radius: 5px;
  border: 1px solid #FF0069;
  background: #FF0069;
  padding: 3px 5px 3px 5px;
  line-height: 1.1;
  min-width: 30px;
  text-align: center;
}


/* Hiding */

.cstmaudioVoiceWrapper.cstmaudiomusicWrapper .rhap_controls-section {
  position: relative;
}

.cstmaudioVoiceWrapper.cstmaudiomusicWrapper .rhap_controls-section .cstmScrub_bar-outer__holder .p-4-0 {
  background: transparent;
}

.cstmaudioVoiceWrapper.cstmaudiomusicWrapper .rhap_controls-section .cstmScrub_bar-outer__holder .p-4.p-4-1 {
  background: transparent;
}

.cstmaudioVoiceWrapper.cstmaudiomusicWrapper .rhap_controls-section .cstmScrub_bar-outer__holder {
  background: transparent;
}

.cstmaudioVoiceWrapper.cstmaudiomusicWrapper .rhap_controls-section .horizonLine ~ .rhap_progress-container {
  position: absolute;
  left: 32px;
  right: 120px;
}

/* input range color */

.range-input {
  --SliderColor: #ff0069;
  -webkit-appearance: none;
  border: none;
}

/* -------------------------- Firefox */
.range-input::-moz-range-thumb { 
  -moz-appearance: none;
  background-color: #ff0069;
  border: none;
}

/* input range color */


#text-logo-content:focus {
  outline: none; /* Remove the default focus outline */
  --tw-ring-color: transparent;
}


.outline_focus:focus{
  outline: none;
  box-shadow: none;
}

.table_wrapper {
  max-height: 600px; /* Adjust the max height as needed */
  overflow-y: scroll; /* Enable vertical scroll for the table body */
}

.table_header {
  position: sticky;
  top: 0px;
  background-color: #525252;
  z-index: 2; /* Ensure the header is above the scrolling body */
}

.cstm_dropdown {
    right: 40px;
}


.table_wrapper::-webkit-scrollbar {
  width: 5px !important;
}
.table_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table_wrapper::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}
.table_wrapper:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table_wrapper:hover::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}


*::-webkit-scrollbar {
  width: 5px !important;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
*::-webkit-scrollbar-thumb {
  background: #b6b6b6 !important;
}
*::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c !important;
}

.authBody-holder {
  min-height: calc(100vh - 60px - 72px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ratiobx-holder .box-holder.selected,
.ratiobx-holder input:checked ~ .box-holder {
  border-color: #ff0069;
}

.ratiobx-holder input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.stripe-pricetable{
  width: 100%;
}

.skiptranslate span {
  display: none !important; /* Hide the span element inside skiptranslate */
}

#google_translate_element .skiptranslate {
  visibility: hidden !important; /* Hide the text without affecting layout */
}

#google_translate_element .skiptranslate > * {
  visibility: visible !important; /* Make sure child elements are still visible */
}

.skiptranslate.goog-te-gadget {
  display: flex;
  flex-direction: column-reverse;
}

.skiptranslate.goog-te-gadget > * {
  margin: 0 !important;
}

.skiptranslate.goog-te-gadget > .goog-te-combo {
  order: 1;
}

.goog-te-combo{
  margin-bottom: 14px !important;
}

.custom-flex {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.video-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: white;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.3s;
}

.video-container .play-button:hover {
  opacity: 1;
}

/* button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} */

@keyframes progress-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 20px 0;
  }
}

.animate-progress {
  animation: progress-stripes 1s linear infinite;
}

.drag-corner {
  border: 3px solid #07ffff;
}

.drag-corner-top-left {
  width: 15px;
  height: 15px;
  top: 7px;
  left: 7px;
}

.drag-corner-top-right {
  width: 15px;
  height: 15px;
  top: 7px;
  right: 7px;
}

.drag-corner-bottom-left {
  width: 15px;
  height: 15px;
  left: 7px;
  bottom: 7px;
}

.drag-corner-bottom-right {
  width: 15px;
  height: 15px;
  right: 7px;
  bottom: 7px;
}

.thmbWraper .video-thumb {
  min-height: 120px;
}

.thmbWraper button {
  color: #fff;
}
.end-frame{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.plus-btn, .minus-btn{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-size-holder button svg{
  width: 13px;
}

.dv-scroll-holder{
  max-height: 60rem;
}


 @media only screen and (max-width:1199px){
  .end-frame{
    grid-template-columns: repeat(2, 1fr);
  }
 }
 @media only screen and (max-width:1023px){
  .end-frame{
    grid-template-columns: repeat(3, 1fr);
  }
 }
 @media only screen and (max-width:660px){
  .end-frame{
    grid-template-columns: repeat(2, 1fr);
  }
 }
 @media only screen and (max-width:480px){
  .end-frame{
    grid-template-columns: repeat(1, 1fr);
  }
 }
 

 @keyframes fadeIn {
  0% {
    opacity: 0; /* Fully transparent at the start */
  }
  100% {
    opacity: 1; /* Fully opaque at the end */
  }
}
 
.fade {
  animation: fadeIn 3s ease-in-out; /* Duration of 1 second (30 frames at 30 FPS) */
}

@keyframes spring-scale {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1); /* Slight overshoot for spring effect */
  }
}

.scale {
  animation: spring-scale 0.8s ease-in-out;
}

@keyframes slide-animation {
  0% {
    transform: translate(-200px, 0px); /* Initial position (150px left, 100px below) */
  }
  100% {
    transform: translate(0, 0); /* Final position */
  }
}

.slide {
  animation: slide-animation 0.8s ease-in;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  100% {
    transform: rotate(360deg); /* Complete a full rotation */
  }
}

.rotate {
  animation: rotate-animation 1s linear forwards;
}

@keyframes bounce-animation {
  0% {
    transform: translateY(0); /* Starting position */
  }
  33% {
    transform: translateY(-30px); /* Bounce upward */
  }
  66% {
    transform: translateY(15px); /* Fall back slightly */
  }
  100% {
    transform: translateY(0); /* Return to original position */
  }
}

.bounce {
  animation: bounce-animation 1.5s ease-in-out;
}

@keyframes flash-animation {
  0% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Hidden */
  }
  100% {
    opacity: 1; /* Fully visible again */
  }
}

.flash {
  animation: flash-animation 0.67s linear infinite; /* Matches 20 frames at ~30fps */
}